import { useCallback, useEffect, useRef, useState } from "react"
import Head from "next/head"
import Image from "next/image"
import { auth } from "@utils/firebase"
import * as Sentry from "@sentry/nextjs"
import { useRouter, useSearchParams } from "next/navigation"
import { useCookies } from "react-cookie"
import { PhoneTwoTone, LockTwoTone } from "@ant-design/icons"
import { Row, Col, Form, Input, Button, Layout, message } from "antd"
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth"
import { useAdminLogInMutation } from "@generated/graphql"

const { Content } = Layout

function LoginIndex() {
  const router = useRouter()
  const searchParams = useSearchParams()
  const recaptchaWrapperRef = useRef<HTMLDivElement>(null)
  const phoneNumberInputRef = useRef<any>(null)
  const [captcha, setCaptcha] = useState("")
  const [showOTPInput, setShowOTPInput] = useState(false)
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [isOTPLoggingIn, setIsOTPLoggingIn] = useState(false)
  const [cookies, setCookie] = useCookies(["adminToken"])

  const [adminLogInMutation] = useAdminLogInMutation()

  useEffect(() => {
    if (phoneNumberInputRef.current) {
      phoneNumberInputRef.current.focus()
    }
  }, [phoneNumberInputRef])

  const otpInputRef = useCallback((node: any) => {
    if (node !== null) {
      node.focus()
    }
  }, [])

  const onPhoneNumberFormFinish = async (values: any) => {
    setIsLoggingIn(true)
    const { phoneNumber } = values
    if (phoneNumber.length === 12 && phoneNumber.indexOf("+") === -1) {
      setIsLoggingIn(true)
      const localWindow = (window as any)
      localWindow.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
        size: "invisible",
        callback(response: string) {
          setCaptcha(response)
        },
        "expired-callback": function () {
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
        },
      })
      localWindow.recaptchaVerifier.render().then((widgetId: any) => {
        localWindow.recaptchaWidgetId = widgetId
      })
      const appVerifier = (window as any).recaptchaVerifier
      signInWithPhoneNumber(auth, `+${values.phoneNumber}`, appVerifier)
        .then((confirmationResult) => {
          setIsLoggingIn(false)
          setShowOTPInput(true);
          (window as any).confirmationResult = confirmationResult
        }).catch((error: Error) => {
          message.error(error.message)
          setIsLoggingIn(false)
          setShowOTPInput(false)
          setCaptcha("")
          Sentry.captureException(error)
          if ((window as any).recaptchaVerifier && recaptchaWrapperRef.current) {
            (window as any).recaptchaVerifier.clear()
            recaptchaWrapperRef.current.innerHTML = "<div id=\"recaptcha-container\"></div>"
          }
        })
    } else {
      setIsLoggingIn(false)
      message.error("Enter valid phone number!")
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    message.error(errorInfo.errorFields[0].errors[0])
  }

  const signInWithPhone = (idToken: string) => {
    adminLogInMutation({ variables: { data: { idToken } } }).then((res) => {
      setCookie("adminToken", res.data?.adminLogIn.authToken, {
        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        path: "/",
        expires: new Date(`${res.data!!.adminLogIn.expiresAt}`)
      })
      const redirect = searchParams.get("redirect")
      if (redirect) {
        window.location.href = redirect
      } else {
        window.location.href = "/dashboard"
      }
    }).catch((error: Error) => {
      message.error(error.message)
      Sentry.captureException(error)
      setShowOTPInput(false)
      setCaptcha("")
      setIsOTPLoggingIn(false)
      if ((window as any).recaptchaVerifier && recaptchaWrapperRef.current) {
        (window as any).recaptchaVerifier.clear()
        recaptchaWrapperRef.current.innerHTML = "<div id=\"recaptcha-container\"></div>"
      }
    })
  }

  const onOTPFormFinish = async (values: any) => {
    setIsOTPLoggingIn(true)
    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
      const localWindow = (window as any)
      localWindow.confirmationResult.confirm(values.otpcode).then(() => {
        auth.currentUser?.getIdToken(true).then((idToken: string) => {
          signInWithPhone(idToken)
        })
      }).catch((error: Error) => {
        message.error(error.message)
        Sentry.captureException(error)
        setShowOTPInput(false)
        setCaptcha("")
        setIsOTPLoggingIn(false)
        if ((window as any).recaptchaVerifier && recaptchaWrapperRef.current) {
          (window as any).recaptchaVerifier.clear()
          recaptchaWrapperRef.current.innerHTML = "<div id=\"recaptcha-container\"></div>"
        }
      })
    }
  }

  return (
    <>
      <Head><title>Login | My Skill Shaala Admin Admin</title></Head>
      <Layout style={{ backgroundColor: "#ffd633", backgroundImage: "url(assets/img/intro-bg.png)", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
        <Content style={{ minHeight: "88vh" }}>
          <Row>
            <Col
              xs={{ span: 18, offset: 3 }}
              lg={{ span: 6, offset: 9 }}
              md={{ span: 12, offset: 6 }}>
              <div style={{ marginTop: "80px", textAlign: "center" }}>
                <Image alt="logo" src="/assets/img/logo-black.webp" height="200" width="250" />
              </div>
              {captcha === "" && showOTPInput === false && (
                <Form
                  autoComplete="off"
                  name="phone-form"
                  style={{ backgroundColor: "white", padding: "24px 36px 24px 36px", marginTop: "36px" }}
                  layout="vertical"
                  initialValues={{ remember: true }}
                  onFinish={onPhoneNumberFormFinish}
                  onFinishFailed={onFinishFailed}>
                  <Form.Item
                    label="Phone Number"
                    name="phoneNumber"
                    rules={[{ required: true, message: "Please enter your phone Number!" }]}>
                    <Input prefix={<PhoneTwoTone className="phone-icon" />} placeholder="Enter your phone number" ref={phoneNumberInputRef} />
                  </Form.Item>

                  <Form.Item>
                    <Button style={{ width: "100%", backgroundColor: "#6630da", borderColor: "#6630da", fontWeight: 700 }} loading={isLoggingIn} type="primary" htmlType="submit">
                      Send OTP
                    </Button>
                  </Form.Item>
                </Form>
              )}
              <div ref={recaptchaWrapperRef}>
                <div id="recaptcha-container" />
              </div>
              {showOTPInput && (
                <Form
                  autoComplete="off"
                  name="otp-form"
                  style={{ backgroundColor: "white", padding: "24px 36px 24px 36px", marginTop: "36px" }}
                  layout="vertical"
                  onFinish={onOTPFormFinish}
                  onFinishFailed={onFinishFailed}>
                  <Form.Item
                    label="OTP"
                    name="otpcode"
                    rules={[{ required: true, message: "Please enter OTP!" }]}>
                    <Input prefix={<LockTwoTone />} placeholder="Enter OTP" ref={otpInputRef} />
                  </Form.Item>
                  <Form.Item>
                    <Button style={{ width: "100%", backgroundColor: "#6630da", borderColor: "#6630da", fontWeight: 700 }} type="primary" loading={isOTPLoggingIn} htmlType="submit">
                      Log In
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  )
}

export default LoginIndex
